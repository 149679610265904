import type { Customisations } from '@lorikeetai/widget/types'

export function generateCustomTheme(theme: Customisations['theme']) {
  if (!theme) {
    return ''
  }

  return `html.rebrand {
    ${theme.fontFamily ? '--lk-font-family-base: ' + theme.fontFamily + ';' : ''}
    ${theme.fontFamily ? '--lk-font-family-serif: ' + theme.fontFamily + ';' : ''}
    ${theme.subtleBrandColor ? '--lk-bg-primary-subtle: ' + theme.subtleBrandColor + ';' : ''}
    ${theme.brandColor ? '--lk-bg-primary: ' + theme.brandColor + ';' : ''}
    ${theme.textColor ? '--lk-text-default: ' + theme.textColor + ';' : ''}
    ${theme.linkColor ? '--lk-text-link: ' + theme.linkColor + ';' : ''}
    ${theme.linkHoverColor ? '--lk-text-link-hover: ' + theme.linkHoverColor + ';' : ''}
    ${!theme.linkColor && theme.brandColor ? '--lk-text-link: ' + theme.brandColor + ';' : ''}
    ${theme.brandColor ? '--lk-border-primary: ' + theme.brandColor + ';' : ''}
    ${theme.secondaryBrandColor ? '--lk-bg-secondary: ' + theme.secondaryBrandColor + ';' : ''}
    }`
}
