import type { Customisations } from '@lorikeetai/widget/types'
import { ButtonIcon, TooltipProvider } from '@optechai/design-system'
import type { LoaderFunctionArgs } from '@remix-run/node'
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { MessageCirclePlusIcon, XIcon } from 'lucide-react'

import '@optechai/design-system/styles/global.css'
import '@optechai/design-system/styles/fonts/roboto/roboto.css'

import { CustomisationContext } from '@web/components/message/messages'

import { ChatHeader } from './components/chat-header'
import { generateCustomTheme } from './generate-custom-theme'

export const loader = async (
  args: LoaderFunctionArgs,
): Promise<{
  customisations: Customisations
}> => {
  const urlData = new URL(args.request.url)
  const customisations = urlData.searchParams.get('customisations')

  if (customisations) {
    const fromBase64 = Buffer.from(customisations, 'base64').toString('utf-8')
    const asJSON = JSON.parse(fromBase64)

    return {
      customisations: asJSON,
    }
  }

  return {
    customisations: {
      title: 'Lorikeet AI Agent',
    },
  }
}

function App() {
  const { customisations } = useLoaderData<typeof loader>()
  const theme = generateCustomTheme(customisations.theme)

  return (
    <html className="rebrand h-full" lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="website" name="og:type" />
        <meta content="Lorikeet CX" name="og:title" />
        <base target="_parent"></base>
        <meta
          content="AI-backed solution for managing, creating and editing workflows."
          name="og:description"
        />
        <meta
          content="https://app.lorikeetcx.ai/og-image.svg"
          name="og:image"
        />
        <meta content="https://app.lorikeetcx.ai" name="og:url" />
        <Meta />
        <Links />
      </head>
      <body className="flex h-full flex-col overflow-hidden bg-surface-sunken">
        {theme && <style>{theme}</style>}
        <CustomisationContext.Provider value={customisations}>
          <TooltipProvider delayDuration={400}>
            <ChatHeader>
              <h1 className="text-inverse font-heading-h2">
                {customisations.title}
              </h1>
              <div className="flex">
                <ButtonIcon
                  icon={MessageCirclePlusIcon}
                  label="Start new chat"
                  onClick={() => {
                    parent.postMessage(
                      {
                        type: 'startNewChat',
                      },
                      '*',
                    )
                  }}
                  size="large"
                  type="button"
                />
                <ButtonIcon
                  icon={XIcon}
                  label="Close"
                  onClick={() => parent.postMessage({ type: 'closeChat' }, '*')}
                  size="large"
                  type="button"
                />
              </div>
            </ChatHeader>
            <Outlet />
          </TooltipProvider>
        </CustomisationContext.Provider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export default withSentry(App)

const CustomBoundary = () => {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    return (
      <html className="rebrand" lang="en">
        <head>
          <title>Page Not Found</title>
          <Meta />
          <Links />
        </head>
        <body className="bg-surface-sunken">
          <div className="flex min-h-screen flex-col items-center justify-center py-2">
            <h1 className="font-bold font-heading-h2">{error.status}</h1>
            <h2 className="font-bold font-heading-h3">{error.statusText}</h2>
            <p className="font-text-m">
              {error.status === 400 ? error.data : 'Page not found'}
            </p>
          </div>
          <Scripts />
        </body>
      </html>
    )
  }

  return (
    <html className="rebrand" lang="en">
      <head>
        <title>Server Error</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-surface-sunken">
        <div className="flex min-h-screen flex-col items-center justify-center py-2">
          <h1 className="font-bold font-heading-h2">Server Error</h1>
          <p className="font-text-m">
            The Lorikeet team have been notified. Please refresh and try again.
            If you&apos;re stuck, reach out or email{' '}
            <a href="mailto:support@lorikeetcx.ai">support@lorikeetcx.ai</a>
          </p>
        </div>
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = CustomBoundary
